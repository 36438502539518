(function(wnd){
  function TextOpener(container) {
    try {
      if (!container || (container && container.length == 0)) {
        throw 'Container is required';
      }

      this.container = container;
      this.init();
    } catch (error) {
      console.error(error);
    }
  }

  TextOpener.prototype = {
    container: undefined,
    textContent: undefined,
    lines: 2,
    openText: 'olvassa tovább',
    closeText: 'bezárás',
    button: undefined,
    active: false,
    opened: false,
    fromWidth: 0,
    init: function() {
      this.setParams();
      this.setElements();
      this.addDOMEvents();
      this.container.removeAttr('style');
      this.checkContentHeight();

      this.container.addClass('initialized');
    },
    addDOMEvents: function() {
      var _self = this;

      this.button.click(function(){
        _self.changeContentSize(!_self.opened);
      });

      var resizeFn = function() {
        _self.checkContentHeight();
      };
      window.addEventListener('resize', resizeFn);
    },
    setElements: function() {
      this.button = this.container.find('.text-opener-link');
      if (this.button.length === 0) {
        this.button = $('<a>', {
          'href': 'javascript:void(0)',
          'class':'text-opener-link',
          'html': '<span class="text"><span class="open">'+this.openText+'</span><span class="close">'+this.closeText+'</span></span><svg class="icon arrow-down"><use xlink:href="#arrow-down" /></svg>'
        });
        this.container.append(this.button);
      }

      this.textContent = this.container.find('.closable-content');


      this.container.addClass('text-opener-content');
    },
    setParams: function() {
      var openText = this.container.data('open-label');
      var closeText = this.container.data('close-label');
      var lines = this.container.data('lines');
      var fromWidth = this.container.data('from-width');

      if (openText && openText.trim()) {
        this.openText = openText;
      }

      if (closeText && closeText.trim()) {
        this.closeText = closeText;
      }

      if (!isNaN(lines)) {
        this.lines = parseInt(lines);
      }

      if (!isNaN(fromWidth)) {
        this.fromWidth = parseInt(fromWidth);
      }
    },
    checkContentHeight: function() {
      var limit = this.getLimit();
      var height = this.textContent.outerHeight(true);
      var windowWidth = window.innerWidth;

      if ((!this.fromWidth || this.fromWidth >= windowWidth)) {
        if (limit < height && !this.active) {
          this.container.addClass('active');
          this.active = true;
          this.container.height(limit);
        } else if (limit >= height && this.active) {
          this.container.removeClass('active');
          this.active = false;
          this.container.removeAttr('style');
        } else if (!this.opened && this.active && limit != height) {
          this.container.height(limit);
        }
      } else if (this.fromWidth && this.fromWidth < windowWidth && this.active) {
        this.active = false;
        this.container.removeClass('active');
        this.container.removeAttr('style');
      }
    },
    changeContentSize: function(open) {
      this.opened = open;

      if (open) {
        this.container.removeAttr('style');
        this.container.addClass('opened');
      } else {
        this.container.removeClass('opened');
        this.container.height(this.getLimit());
      }
    },
    getLimit: function() {
      var lineHeight = this.textContent.css('line-height');

      if (isNaN(lineHeight)) {
        lineHeight = parseFloat(lineHeight.replace('px', ''));
      }
      return limit = lineHeight * this.lines;
    }
  };

  wnd.TextOpener = TextOpener;
})(window);
