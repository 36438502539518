(function(window){
  function NetkazanMegaMenu(container, params) {
    try {
      if (!container || (container && container.length == 0)) {
        throw 'Container is required!';
      }

      this.container = container;
      if (params) {
        this.setParams(params);
      }

      this.init();
    } catch (error) {
      console.error(error);
    }
  }

  NetkazanMegaMenu.prototype = {
    container: undefined,
    innerContent: undefined,
    mainMenuItems: undefined,
    secondMenuContainer: undefined,
    thirdMenuContainer: undefined,
    logoContainer: undefined,
    headerOpener: undefined,
    megamenuButton: undefined,
    bannerContent: undefined,
    secondLevelItems: {},
    thirdLevelItems: {},
    banners: {},
    init: function() {
      this.setElements();
      this.addDOMEvents();
    },
    setElements: function() {
      var _self = this;

      this.innerContent = this.container.find('[data-purpose="megamenu-inner"]');

      this.logoContainer = $('[data-purpose="logo-container"]');
      if (this.logoContainer.length > 0) {
        this.headerOpener = $('[data-purpose="megamenu-opener"]');
      }

      this.megamenuButton = $('[data-purpose="megamenu-button"]');
      this.mainMenuItems = this.container.find('[data-purpose="megamenu-item"]');
      this.secondMenuContainer = this.container.find('[data-purpose="second-level-container"]');
      this.thirdMenuContainer = this.container.find('[data-purpose="third-level-container"]');
      this.firstMenuContainer = this.container.find('[data-purpose="first-level-container"]');
      this.bannerContent = this.container.find('.banner-content');

      this.container.find('[data-purpose="second-level-menu"]').each(function(){
        _self.secondLevelItems[$(this).data('parent')] = $(this);
      });

      this.container.find('[data-purpose="third-level-menu"]').each(function(){
        _self.thirdLevelItems[$(this).data('parent')] = $(this);
      });
    },
    addDOMEvents: function() {
      var _self = this;

      if (this.mainMenuItems && this.mainMenuItems.length > 0) {
        this.mainMenuItems.hover(function(){
          var level = parseInt($(this).data('level'));
          var id = $(this).data('id');
          var target = undefined;
          if (id) {
            if (level === 1 && typeof _self.secondLevelItems[id] !== 'undefined') {
              target = _self.secondLevelItems[id];
              _self.checkBanner(id);
            } else if (level === 2 && typeof _self.thirdLevelItems[id] !== 'undefined') {
              target = _self.thirdLevelItems[id];
            }


            if (target) {
              _self.container.addClass('opened')
              _self.showMenu(level+1, target);
            } else if (_self.container.hasClass('opened') && level === 1) {
              _self.container.removeClass('opened');
              _self.secondMenuContainer.removeClass('active');
            } else if (level === 2 && _self.thirdMenuContainer.hasClass('active')) {
              _self.thirdMenuContainer.removeClass('active');
            }

            if (level < 3) {
              if (level === 2) {
                _self.secondMenuContainer.find('.active-item').removeClass('active-item');
              } else if (level === 1) {
                _self.secondMenuContainer.find('.active-item').removeClass('active-item');
                _self.firstMenuContainer.find('.active-item').removeClass('active-item');
              }

              $(this).addClass('active-item');
            }
          }
        });
      }

      if (this.headerOpener && this.headerOpener.length > 0) {
        this.headerOpener.hover(function(){
          _self.openListContent();
        }, function(e) {
          var target = $(e.relatedTarget);
          var valid = target.hasClass('logo-content-outer') || target.parents('.megamenu').length > 0;

          if (!valid) {
            _self.closeMegamenu();
          }
        });
      }

      if (!this.container.hasClass('always-open') && this.megamenuButton && this.megamenuButton.length > 0) {
        this.megamenuButton.hover(function(){
          _self.openListContent();
        }, function(e){
          var target = $(e.relatedTarget);
          var valid = target.hasClass('logo-content-outer') || target.parents('.megamenu').length > 0;

          if (!valid) {
            _self.closeMegamenu();
          }
        });
      }

      if (this.innerContent) {
        this.innerContent.hover(function(){

        }, function(){
          _self.closeMegamenu();
        });
      }

      $(document).on('netkazan.header_position_changed', function(){
        _self.closeMegamenu();
      });
    },
    openListContent: function() {
      if (!this.container.hasClass('visible')) {
        this.container.addClass('visible');
        if (this.logoContainer && this.logoContainer.length > 0) {
          this.logoContainer.addClass('hovered');
        }
      }
    },
    closeMegamenu: function() {
      this.container.removeClass('opened visible');
      this.secondMenuContainer.removeClass('active');
      this.thirdMenuContainer.removeClass('active');
      this.firstMenuContainer.find('.active-item').removeClass('active-item');
      this.logoContainer.removeClass('hovered');
      $('.megamenu-list-container').scrollTop(0);
    },
    showMenu: function(level, item) {
      if (level === 2) {
        if (!this.secondMenuContainer.hasClass('active')) {
          this.secondMenuContainer.addClass('active');
        }

        if (this.thirdMenuContainer.hasClass('active')) {
          this.thirdMenuContainer.removeClass('active');
        }

        this.secondMenuContainer.find('.active').removeClass('active');
        this.thirdMenuContainer.find('.active').removeClass('active');
      } else if (level === 3) {
        if (!this.thirdMenuContainer.hasClass('active')) {
          this.thirdMenuContainer.addClass('active');
        }

        this.thirdMenuContainer.find('.active').removeClass('active');
      }

      item.addClass('active');

    },
    setParams: function(params) {
      if (params.banners) this.setBanners(params.banners);
    },
    setBanners: function(banners) {
      this.banners = $.extend({}, this.banners, banners);
    },
    checkBanner: function(categoryId) {
      var keys = Object.keys(this.banners);
      if (keys && keys.length > 0) {
        var banner = typeof this.banners[categoryId] !== 'undefined' ? this.banners[categoryId] : undefined;

        if (banner) {
          var content = $('<a>', {
            href:banner.link,
          });
          switch (banner.type) {
            case 'image':
              content.addClass('image-banner');
              content.attr('title', banner.name);
              content.html('<img src="'+banner.image+'" alt="'+banner.bane+'" />');
              break;
            case 'product':
              content.attr('title', banner.name);
              content.addClass('product-card');

              var inner = $('<span>', {
                class:'inner without-parameters'
              });

              var imageContainer = $('<span>', {
                class:"image-outer",
                html:'<img src="'+banner.image+'" alt="'+banner.name + ' ' + banner.category+'" />'
              })

              if (typeof banner.tags === 'object') {
                var tagsOuter = $('<span>', {
                  class:'tags-container'
                });

                var tagsTop = $('<span>', {
                  class:'tags-top'
                });

                var tags = Object.keys(banner.tags);
                for (var i = 0; i < tags.length; i++) {
                  var tag = tags[i];
                  var icon = tag === 'shipping' ? '<svg class="icon shipping-tag-icon"><use xlink:href="#shipping-tag-icon" /></svg>' : '';
                  tagsTop.append('<span class="'+tag+'-tag">'+icon+'<span class="text">'+banner.tags[tag]+'</span></span><br/>');
                }


                tagsOuter.append(tagsTop);
                imageContainer.append(tagsOuter);
              }

              inner.append(imageContainer);
              inner.append('<span class="text-content"><span class="name-outer"><span class="title"><span class="name">'+banner.name+'</span> '+banner.category+'</span></span></span>')

              content.append(inner);

              break;
          }

          this.bannerContent.html('');
          this.bannerContent.append(content);
        }
      }
    }
  };

  window.MegaMenu = NetkazanMegaMenu;
})(window);
