function ProductSlider(container, params) {
  try {
    if (!container) {
      throw 'Container is required';
    }

    if (!container.hasClass('product-slider-initialized')) {
      this.container = container;

      if (params) {
        this.setParams(params);
      }

      this.init();
    }
  } catch (error) {
    console.error(error);
  }
}

ProductSlider.prototype = {
  container:undefined,
  breakpoint: 1024,
  pagerPrev: undefined,
  windowWidth: undefined,
  sliderContainer: undefined,
  sliderInner:undefined,
  sliderWidth:0,
  containerWidth:0,
  boxWidth:undefined,
  afterLoadUrl: undefined,
  loadedContent: false,
  ajaxLoadFn: undefined,
  init: function() {
    this.container.addClass('product-slider-initialized');
    this.windowWidth = window.innerWidth;
    this.setElements();
    this.setContainerWidth();
    this.setSliderContentWidth();
    this.addDOMEvents();
    this.slide(0);
  },
  setElements: function() {
    this.sliderContainer = this.container.find('[data-purpose="slider-container"]');
    this.sliderInner = this.container.find('[data-purpose="slider-inner"]').first();
    this.pagerNext = this.container.find('[data-purpose="next-pager"]');
    this.pagerPrev = this.container.find('[data-purpose="prev-pager"]');

    var afterLoadUrl = this.container.data('load-url');
    if (afterLoadUrl) {
      this.afterLoadUrl = afterLoadUrl;
    }
  },
  addDOMEvents: function() {
    var _self = this;

    if (this.pagerNext.length > 0) {
      this.pagerNext.click(function(){
          _self.slide(-1);
      });
    }

    if (this.pagerPrev.length > 0) {
        this.pagerPrev.click(function(){
            _self.slide(1);
        });
    }

    $(document).on('netkazan.window_width_changed', function(e, newWidth){
      _self.windowWidth = newWidth;
      _self.setContainerWidth();
      _self.setSliderContentWidth();
      _self.slide(0);
    });

    if (this.afterLoadUrl) {
      this.ajaxLoadFn = function() {
        _self.loadProducts();

        window.removeEventListener('scroll', _self.ajaxLoadFn);
        window.removeEventListener('touchend', _self.ajaxLoadFn);
        window.removeEventListener('touchmove', _self.ajaxLoadFn);
      }

      window.addEventListener('scroll', _self.ajaxLoadFn);
      window.addEventListener('touchend', _self.ajaxLoadFn);
      window.addEventListener('touchmove', _self.ajaxLoadFn);
    }
  },
  setParams: function(params) {
    if (params.breakpoint) this.setBreakpoint(params.breakpoint);
  },
  setBreakpoint: function(breakpoint) {
    this.breakpoint = breakpoint;
  },
  getBreakpoint: function() {
    return this.breakpoint;
  },
  setSliderContentWidth: function() {
    var width = 0;
    if (this.sliderInner) {
      var _self = this;

      this.sliderInner.children().each(function(index){
          var tmp = $(this).outerWidth(true)
          width += tmp;

          if (index === 0) {
            _self.boxWidth = tmp;
          }
      });

      this.sliderInner.width(width);
    }

    this.sliderWidth = width;
  },
  getSliderContentWidth: function() {
    return this.sliderWidth;
  },
  setContainerWidth: function() {
    this.containerWidth = this.sliderContainer.outerWidth();
  },
  getContainerWidth: function() {
    if (!this.containerWidth) {
      this.setContainerWidth();
    }
    return this.containerWidth;
  },
  slide: function(direction) {
    if (!direction) {
      direction = 0;
    }

    var innerWidth = this.getContainerWidth();
    var boxWidth = this.getBoxWidth();
    var stepSize = Math.floor((innerWidth) / boxWidth) * boxWidth;
    var currentSlide = parseInt(this.sliderInner.data('current-slide'));
    var contentWidth = this.getSliderContentWidth();

    if (isNaN(currentSlide)) {
        currentSlide = 0;
    }

    currentSlide += (stepSize * direction);

    if (currentSlide >= 0) {
      currentSlide = 0;
      if (this.pagerPrev.hasClass('active')) {
        this.pagerPrev.removeClass('active');
        this.pagerPrev.parent().removeClass('active');
      }
    }


    if (contentWidth <= Math.abs(currentSlide) + innerWidth) {
      currentSlide = -1 * contentWidth + innerWidth;
      if (contentWidth < innerWidth) {
        currentSlide = 0;
      }

      if (this.pagerNext.hasClass('active')) {
          this.pagerNext.removeClass('active');
          this.pagerNext.parent().removeClass('active');
      }
    } else {
      if (!this.pagerNext.hasClass('active')) {
        this.pagerNext.addClass('active');
        this.pagerNext.parent().addClass('active');
      }
    }

    if (currentSlide < 0) {
      if (!this.pagerPrev.hasClass('active')) {
          this.pagerPrev.addClass('active');
          this.pagerPrev.parent().addClass('active');
      }
    }


    if (this.windowWidth >= this.getBreakpoint()) {
      this.sliderInner.css({
        'transform':'translateX('+currentSlide+'px)',
        'width':contentWidth+'px'
      });
    } else {
      this.sliderInner.css({
        'transform':'translateX(0px)'
      });
      this.sliderContainer.scrollLeft(0);
    }

    this.sliderInner.data('current-slide', currentSlide);
  },
  getBoxWidth: function() {
    return this.boxWidth;
  },
  loadProducts: function() {
    if (!this.loadedContent && this.afterLoadUrl) {
      this.loadedContent = true;
      var _self = this;
      var type = this.container.data('ajax-type');
      if (!type) {
        type = 'json';
      }

      $.ajax({
        url: this.afterLoadUrl,
        type: 'get',
        dataType: type,
        success: function(response) {
          var data = undefined;
          switch (type.toLowerCase()) {
            case 'json':
              data = response.html;
              break;
            case 'html':
              data = response;
              break;
          }

          if (data) {
            _self.sliderInner.append(data);
            _self.setContainerWidth();
            _self.setSliderContentWidth();
            _self.slide(0);

            $(document).trigger('netkazan.ajax_slider_loaded');
          }
        }
      });
    }
  }
}

window.ProductSlider = ProductSlider;
