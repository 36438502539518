(function(wnd){
  function StorageInfoPopup(params) {
    try {
      if (params) {
        this.setParams(params);
      }

      this.init();
    } catch (error) {
      console.error(error);
    }
  }

  StorageInfoPopup.prototype = {
    url: '',
    remodalContainer: undefined,
    innerContent: undefined,
    lastCreated: null,
    storageInfos: {},
    init: function() {
      if (this.url) {
        this.setElements();
        this.addDOMEvents();
      }
    },
    setElements: function() {
      this.remodalContainer = $(document).find('[data-remodalwindow-id="storage-info"]');
      if (this.remodalContainer && this.remodalContainer.length > 0) {
        this.innerContent = this.remodalContainer.find('.inner-content');
      }
    },
    addDOMEvents: function() {
      var _self = this;

      if (this.remodalContainer && this.remodalContainer.length > 0) {
        $('[data-purpose="storage-info-button"]').click(function(){
          var productId = $(this).data('product-id');
          if (productId) {
            _self.getStorageInfo(productId);
          }
        });
      }
    },
    setParams: function(params) {
      if (params.url) this.setUrl(params.url);
    },
    setUrl: function(url) {
      this.url = url;
    },
    getStorageInfo: function(id) {
      if (this.lastCreated == id) {
        this.openRemodal();
      } else if (typeof this.storageInfos[id] !== 'undefined') {
        this.createContent(id);
      } else {
        var _self = this;

        $.ajax({
          url: this.url,
          dataType:'json',
          type:'GET',
          data: {
            productId: id
          },
          success: function(response) {
            _self.storageInfos[id] = response;

            _self.createContent(id);
          }
        })
      }
    },
    createContent: function(id) {
      if (typeof this.storageInfos[id] !== 'undefined') {
        var info = this.storageInfos[id];
        this.innerContent.html('');

        var productCard = $('<div>', {
          class:'product-lying-card'
        });

        productCard.append(this.createImageContent(info));
        productCard.append(this.createTextContent(info));

        this.innerContent.append(productCard);

        if (typeof info.storeInfos !== 'undefined') {
          this.innerContent.append(this.createStoreInfoContent(info.storeInfos))
        }

        this.lastCreated = id;
        this.openRemodal();
      }
    },
    createImageContent: function(info) {
      var content = $('<a>', {
        'href': info.link,
        'class': 'image-outer',
      });

      if (typeof info.image !== 'undefined') {
        content.append('<img src="'+info.image+'" alt="'+info.image+'" />');
      }

      var hasTags = false;
      var tagsContainer = $('<span>', {
        class:'tags-container'
      });
      if (typeof info.tags !== 'undefined' && Array.isArray(info.tags)) {
        hasTags = true;
        var topTagsContent = $('<span>', {
          'class': 'tags-top'
        });

        for (var i = 0; i < info.tags.length; i++) {
          topTagsContent.append('<span class="product-tag">'+(info.tags[i])+'</span>');
        }

        tagsContainer.append(topTagsContent);
      }

      if ((typeof info.warranty !== 'undefined' && info.warranty) || (typeof info.shipping !== 'undefined' && info.shipping)) {
        hasTags = true;
        var bottomTagsContent = $('<span>', {
          'class': 'tags-bottom'
        });

        if (typeof info.warranty !== 'undefined' && info.warranty) {
          bottomTagsContent.append('<span class="warranty-tag">'+info.warranty+'</span>');
        }

        if (typeof info.shipping !== 'undefined' && info.shipping) {
          bottomTagsContent.append('<span class="shipping-tag"><svg class="icon shipping-tag-icon"><use xlink:href="#shipping-tag-icon" /></svg><span class="text">'+info.shipping+'</span></span>');
        }

        tagsContainer.append(bottomTagsContent);
      }

      if (hasTags) {
        content.append(tagsContainer);
      }

      return content
    },
    createTextContent: function(info) {
      var content = $('<div>', {
        class: 'text-content'
      });

      var category = typeof info.category !== 'undefined' && info.category ? info.category : undefined;
      var nameOuter = $('<div>', {
        class:'name-outer',
        html: '<h3><a href="'+info.link+'" class="title" title="'+info.name+ ' ' +category+'"><span class="name">'+info.name+'</span></a></h3>'
      });

      if (category) {
        nameOuter.append('<a href="'+info.link+'" class="category-name">'+category+'</a>');
      }

      content.append(nameOuter);

      var storageInfo = $('<span>', {
        'class': "storage-info "+info.storageInfoClass,
      });

      if (info.storageInfoClass === 'in-stock') {
        storageInfo.append('<svg class="icon check-icon"><use xlink:href="#check-icon" /></svg>');
      }

      storageInfo.append('<span class="text">'+info.storageInfoText+'</span>');
      content.append(storageInfo);

      var bottomContent = $('<div>', {
        class:'bottom-content'
      })

      var priceContent = $('<div>', {
        class:'price-content',
      });

      if (typeof info.original_price !== 'undefined') {
        priceContent.append('<span class="row"><span class="label">listaár:</span> <span class="original-price">'+info.original_price+'</span></span>');
      }

      priceContent.append('<span class="row sell-price-row"><span class="label">web ár:</span><span class="price">'+info.price+'</span></span>')
      bottomContent.append(priceContent);
      bottomContent.append('<a href="'+info.addToCartUrl+'" class="button cart-button with-icon"><svg class="icon cart-icon"><use xlink:href="#cart-icon" /></svg></a>');

      content.append(bottomContent);

      return content;
    },
    createStoreInfoContent: function(infos) {
      var content = $('<div>', {
        class:"store-infos"
      });

      if (Array.isArray(infos)) {
        for (var i = 0; i < infos.length; i++) {
          var info = infos[i];

          var box = $('<div>', {
            'class': 'store-box'
          });

          var leftContent = $('<div>', {
            class:'left-content',
            html: '<div class="title">'+info.name+'</div>'
          });

          var storageInfo = $('<div>', {
            class:'storage-info '+info.storageInfoClass,
            html: '<span class="text">'+info.storageInfoText+'</span>'
          });

          leftContent.append(storageInfo);

          var rightContent = $('<div>', {
            'class':'text-content',
            'html': '<div class="address">'+info.address+'</div>'
          });

          if (typeof info.openingDays !== 'undefined' && typeof info.openingHours !== 'undefined' && info.openingDays && info.openingHours) {
            rightContent.append('<div class="info-row"><span class="label">'+info.openingDays+'</span> <span class="value bold-fweight">'+info.openingHours+'</span></div>')
          }

          if (typeof info.phoneNumber !== 'undefined' && info.phoneNumber) {
            var formatted = typeof info.formattedPhoneNumber !== 'undefined' && info.formattedPhoneNumber ? info.formattedPhoneNumber : info.phoneNumber;
            var label = typeof info.phoneLabel !== 'undefined' && info.phoneLabel ? info.phoneLabel : 'Telefon';

            rightContent.append('<div class="info-row"><span class="label bold-fweight">'+label+'</span> <a href="tel:'+info.phoneNumber+'">'+formatted+'</a></div>')
          }

          box.append(leftContent);
          box.append(rightContent);

          content.append(box);
        }
      }

      return content;
    },
    openRemodal: function() {
      this.remodalContainer.remodalwindow().open();
    }
  }

  wnd.StorageInfoPopup = StorageInfoPopup;
})(window);
