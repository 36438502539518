require('./accordion.js');
require('./megamenu.js');
require('./mobileMenu.js');
require('./remodal.js');
require('./productSlider.js');
require('./TextOpener.js');
require('./StorageInfoPopup.js');

var windowWidth = undefined;
var headerBorder = undefined;
var mainHeader = undefined;
var mobileMenu = undefined;
var cartCount = 0;
var headerCartItem = undefined;
var scrollSliders = undefined;

var mobileMenuInterval = undefined;
var loadTry = 0;

$(window).on("load", function(){
  if (!mobileMenuInterval) {
    mobileMenuInterval = setInterval(function(){
      if (typeof mobileMenu !== undefined) {
        if (mobileMenu.hasInitialized()) {
          if (mobileMenu.hasAjaxLoad() && !mobileMenu.hasAjaxLoadStarted()) {
            mobileMenu.loadMenuItems();
          } else {
            clearInterval(mobileMenuInterval);
          }
        }

      }

      loadTry++;
      if (loadTry > 50) {
        clearInterval(mobileMenuInterval);
      }
    }, 40);
  }
});

$(document).ready(function(){
  windowWidth = window.innerWidth;

  var resizeFn = function() {
    if (window.innerWidth != windowWidth) {
      windowWidth = window.innerWidth;
      $(document).trigger('netkazan.window_width_changed', windowWidth);
    }
  };

  mainHeader = $('.main-header');
  headerBorder = $('[data-purpose="header-border"]');
  headerCartItem = $('[data-purpose="header-cart-item"]');
  if (!headerBorder || (headerBorder && headerBorder.length == 0)) {
    headerBorder = mainHeader;
  }

  window.addEventListener('resize', resizeFn);

  $(document).on('netkazan.cart_count_changed', function(e, data){
    var count = data.count;

    $('[data-purpose="cart-counter"]').html(count);

    if (headerCartItem && headerCartItem.length > 0) {
      if (count > 0 && headerCartItem.hasClass('empty-cart')) {
        headerCartItem.removeClass('empty-cart');
      } else if (count <= 0 && !headerCartItem.hasClass('empty-cart')) {
        headerCartItem.addClass('empty-cart');
      }
    }
  });

  $('[data-purpose="add-to-cart"]').click(function(e){
    e.preventDefault();
    cartCount++
    $(document).trigger('netkazan.cart_count_changed', {
      count:cartCount
    });


    var type = $(this).data('type');
    if (type !== 'upsell') {
      openRemodalPopup('cart-upsell');
    }
  });

  $('[data-purpose="remodal-open"]').click(function(){
    var remodalId = $(this).data('popup-target');
    if (remodalId) {
      openRemodalPopup(remodalId);
    }
  });

  $('[data-purpose="details-button"]').click(function(e){
    e.preventDefault();
    var opened = $(this).hasClass("opened");
    if (opened) {
      $(this).removeClass('opened');
      $(this).parents('.product-card').find('.hover-content').slideToggle();
    } else {
      $(this).addClass('opened');
      $(this).parents('.product-card').find('.hover-content').slideToggle();
    }
  });

  $('.accordion-list').each(function(ind, el) {

		var options = {};
		var th = $(el);

		if (th.data('single-active-item') != undefined) options.singleActiveItem = true;
		if (th.data('active-index') != undefined) options.activeIndex = th.data('active-index');
		if (th.data('from-width') != undefined) options.fromWidth = parseInt(th.data('from-width'));

		new AccordionList(th, options);
  });

  var megamenuContainer = $('[data-purpose="megamenu-container"]');
  if (megamenuContainer && megamenuContainer.length > 0) {
    new MegaMenu($('[data-purpose="megamenu-container"]'), {
      banners: typeof megaMenuBanners === 'object' ? megaMenuBanners : {}
    });
  }

  var scrollFn = function() {
    checkHeaderPosition();
  };

  window.addEventListener('scroll', scrollFn);
  window.addEventListener('touchend', scrollFn);
  window.addEventListener('touchmove', scrollFn);

  mobileMenu = new MobileMenu($('#mobileMenu'), {
    sites: typeof mobileMenuSites !=='undefined' ? mobileMenuSites : [],
    renderHTML: false,
    loadWithAjax: true,
    ajaxUrl: '/ajax/mobile-menu-items.json',
    ajaxMethod: 'GET'
  });

  $('[data-purpose="scroll-to-element"]').click(function(){
    scrollToElement($(this))
  });

  $('[data-purpose="quantity-change-button"]').click(function(e) {
    if ($(this).hasClass('inactive')) {
      e.preventDefault();
      return false;
    }

    var parent = $(this).parents('[data-purpose="quantity-control-panel"]');

    if (parent) {
      var field = parent.find('[data-purpose="quantity"]');
      if (field) {
        var zeroAvailable = field.hasClass('zero-available');
        var borderValue = zeroAvailable ? 0 : 1;
        var value = parseInt(field.val()) + parseInt($(this).data('value'));
        var deleteProductRow = zeroAvailable && value <= 0;
        if (isNaN(value) || value <= 0) {
          value = 1;
        }

        field.val(value);
        field.change();

        var minus = parent.find('[data-purpose="quantity-change-button"][data-type="minus"]');
        if (minus) {

          /* Ez csak a kosár oldalon lesz igaz */
          if (deleteProductRow) {
            $(document).trigger('netkazan.confirm_cart_item_delete', {
              field: field
            });
          } else {
            if (value == borderValue) {
              minus.addClass('inactive');
            } else if (value > borderValue && minus.hasClass('inactive')) {
              minus.removeClass('inactive');
            }
          }
        }
      }
    }
  });

  $('[data-purpose="quantity"]').blur(function(){
    var value = $(this).val();
    if (isNaN(value)) {
      $(this).val(1);
    }
  });

  $('[data-purpose="mailtolink"]').click(function(){
    var href = $(this).attr('href');
    if (href === 'javascript:void(0)') {
      var email = $(this).data('user')+'@'+$(this).data('domain');
      $(this).attr('href', 'mailto:'+email);
    }
  });

  $('[data-purpose="text-opener-content"]').each(function(){
    new TextOpener($(this));
  });

  new StorageInfoPopup({
    url:'/ajax/storage-info.json'
  });

  initializeProductSliders();
  initializedNotificationButtons();

  $(document).on('netkazan.scroll_product_slider_loaded', function(){
    initializeProductSliders();
    initializedNotificationButtons();
  });

  $(document).on('netkazan.ajax_slider_loaded', function(){
    initializedNotificationButtons();
  });

  scrollSliders = $('[data-purpose="onscroll-product-slider"]');
  if (scrollSliders && scrollSliders.length > 0) {
    var sliderScrollFn = function() {
      loadProductSliders();

      window.removeEventListener('scroll', sliderScrollFn);
      window.removeEventListener('touchend', sliderScrollFn);
      window.removeEventListener('touchmove', sliderScrollFn);
    }

    window.addEventListener('scroll', sliderScrollFn);
    window.addEventListener('touchend', sliderScrollFn);
    window.addEventListener('touchmove', sliderScrollFn);
  }
});

function checkHeaderPosition() {
  if (headerBorder && headerBorder.length > 0 && !$('body').hasClass('product-page-body')) {
    var scrollTop = $(window).scrollTop();
    var borderTop = headerBorder.outerHeight() + headerBorder.offset().top;
    if (borderTop < scrollTop && !mainHeader.hasClass('fixed')) {
      mainHeader.addClass('fixed');
      $(document).trigger('netkazan.header_position_changed');
    } else if (borderTop >= scrollTop && mainHeader.hasClass('fixed')) {
      mainHeader.removeClass('fixed');
      $(document).trigger('netkazan.header_position_changed');
    }
  }
}

function scrollToElement(obj) {
  var selector = $(obj).data('target');

  if (selector) {
    var item = $(selector);
    var timeout = parseInt(obj.data('timeout'));
    if (isNaN(timeout)) {
      timeout = 300;
    }

    if (item.length > 0) {
      $('html, body').animate({scrollTop: item.offset().top}, timeout);
    }
  }
}

function svg(icon) {
  return '<svg class="icon '+icon+'"><use xlink:href="#'+icon+'" /></svg>';
}

function openRemodalPopup(remodalId) {
  var item = $('[data-remodalwindow-id="'+remodalId+'"]');

  if (item && item.length > 0) {
    $(item).remodalwindow().open();
  }
}

function initializeProductSliders()
{
  $('[data-purpose="product-slider"]:not(.product-slider-initialized)').each(function(){
    new ProductSlider($(this));
  });
}

function initializedNotificationButtons() {
  $('[data-purpose="notification-button"]:not(.initialized)').each(function(){
    $(this).click(function(){
      var productId = $(this).data("product-id");
      var notificationRemodal = $('[data-remodalwindow-id="notificationRemodal"]');

      if (notificationRemodal && notificationRemodal.length > 0) {
        notificationRemodal.find('.product-id').val(productId);

        openRemodalPopup('notificationRemodal');
      }
    });

    $(this).addClass('initialized');
  });
}

function loadProductSliders()
{
  $.each(scrollSliders, function(){
    var item = $(this);
    var url = item.data('load-url');
    if (url) {
      var type = item.data('ajax-type');
      if (!type) {
        type = 'json';
      }

      $.ajax({
        url: url,
        type:'get',
        dataType: type,
        success: function(response) {
          var data = undefined;
          switch (type.toLowerCase()) {
            case 'json':
              data = response.html;
              break;
            case 'html':
              data = response;
              break;
          }

          if (data) {
            $(data).insertBefore(item);
            item.remove();
            initializeProductSliders();

            if (typeof window.lazyLoadInstance !== 'undefined') {
              window.lazyLoadInstance.update();
            }
          }
        }
      });
    }
  });
}
