(function(window){
  function MobileMenu(container, params) {
    try {
      if (!container) {
        throw 'Container is required!';
      }

      this.container = container;
      if (params) {
        this.setParams(params);
      }
      this.init();
    } catch (error) {
      console.error(error);
    }
  }

  MobileMenu.prototype = {
    container:undefined,
    closeBtn: undefined,
    innerContent:undefined,
    renderHtml: false,
    loadWithAjax: false,
    sites: {},
    ajaxUrl: undefined,
    ajaxMethod: 'GET',
    selectors: {
      innerContent: '[data-purpose="mobile-menu-inner"]',
      closeBtn: '.close-menu-btn',
      overlay: '.mobile-menu-overlay',
      trigger: '.mobile-menu-btn'
    },
    texts: {
      backText: 'Vissza'
    },
    currentLevel:1,
    firstLevelContent: undefined,
    submenuContent: undefined,
    opened: false,
    wait: false,
    startLoad: false,
    initialized: false,
    init: function(){
      this.setElements();
      this.addDOMEvents();
      if (this.loadWithAjax) {

      } else {
        this.createContent();
      }

      this.initialized = true;
    },
    setElements: function() {
      this.innerContent = this.container.find(this.getSelector('innerContent'));
      this.closeBtn = this.container.find(this.getSelector('closeBtn'));
    },
    addDOMEvents: function() {
      var _self = this;

      if (this.closeBtn && this.closeBtn.length > 0) {
        this.closeBtn.click(function(){
          _self.changeMenuVisibility();
        });
      }

      $(this.getSelector('overlay')).click(function(){
        _self.changeMenuVisibility();
      });

      $(this.getSelector('trigger')).click(function(){
        _self.changeMenuVisibility();
      });

    },
    addMenuPageDOMEvents: function(page) {
      var _self = this;

      page.find('.has-submenu').click(function() {
        var key = $(this).data('key');

        if (key) {
          _self.createSubmenuContent(key);
        }
      });

      page.find('.back-link').click(function(){
        var key = $(this).data('key');
        if (key) {
          if (_self.currentLevel == 2) {
            _self.resetSubmenuContent(_self.submenuContent);
          } else {
            _self.createSubmenuContent(key);
          }
        }
      });
    },
    resetSubmenuContent: function(page) {
      page.find('.has-submenu').off('click');
      page.find('.back-link').off('click');
      page.remove();
    },
    createContent: function() {
      var types = [];
      if (typeof this.sites.top !== 'undefined') {
        types.push('top');
      }

      if (typeof this.sites.bottom !== 'undefined') {
        types.push('bottom');
      }

      this.firstLevelContent = $('<div>', {
        class:'mobile-menu-page first-level',
        html:'<div class="level-header first-level-header"><a href="/">'+this.writeSvg('netkazan-logo')+'</a></div>'
      });



      if (types.length > 0) {
        var inner = $('<div>', {
          class:'sites-content'
        });

        for (var i = 0; i < types.length; i++) {
          var type = types[i];
          var list = $('<div>', {
            'class':type+"-content"
          });

          var items = this.sites[type];
          if (Array.isArray(items)) {
            for (var j = 0; j < items.length; j++) {
              var children = typeof items[j].children === 'object' && Array.isArray(items[j].children) ? items[j].children : [];
              var listItem = $('<div>', {
                html:$('<a>', {
                  href: typeof items[j].link !== 'undefined' && children.length === 0 ? items[j].link : 'javascript:void(0)',
                  class:"mobile-menu-item first-level-item "+(typeof items[j].extraClass !== 'undefined' ? items[j].extraClass : '') + (children && children.length > 0 ? ' has-submenu' : ''),
                  html: '<span class="text"><span>'+items[j].name+'</span>'+(typeof items[j].icon !== 'undefined' ? this.writeSvg(items[j].icon) : '')+'</span>'+(children.length > 0 ? '<span class="arrow-outer">'+this.writeSvg('mobile-menu-arrow')+'</span>' : ''),
                  'data-index': j,
                  'data-group': type,
                  'data-key':type+'-'+j
                }),
              });

              list.append(listItem);
            }
          }

          inner.append(list);
        }

        this.firstLevelContent.append(inner);

        this.addMenuPageDOMEvents(this.firstLevelContent);

        this.innerContent.append(this.firstLevelContent);
      }
    },
    createSubmenuContent: function(key) {
      var tmp = key.split('-');

      var title = '';
      var items = [];
      var parentKey = [];
      if (tmp.length > 1) {
        var items = typeof this.sites[tmp[0]] !== 'undefined' ? this.sites[tmp[0]] : undefined;
        parentKey.push(tmp[0]);
        for (var i = 1; i < tmp.length; i++) {
          if (i < (tmp.length - 1)) {
            parentKey.push(tmp[i]);
          }
          if (items) {
            title = tmp.length > 2 ? typeof items[tmp[i]] !== 'undefined' ? items[tmp[i]].name : '' : '';
            items = typeof items[tmp[i]] !== 'undefined' && items[tmp[i]] !== 'undefined' && typeof items[tmp[i]].children !== 'undefined' ? items[tmp[i]].children : undefined;
          } else {
            break;
          }
        }
      }

      level = tmp.length;

      if (items.length > 0) {
        var levelClass = '';
        if (!title) {
          title = this.getText('backText');
        }

        switch (parseInt(level)) {
          case 2:
            levelClass = 'second-level';
            break;
          case 3:
            levelClass = "third-level";
            break;
          case 4:
            levelClass = 'fourth-level';
            break;
          case 5:
            levelClass = 'fifth-level';
            break;
        }

        var content = $('<div>', {
          'class': 'mobile-menu-page active '+levelClass,
          'html':'<div class="level-header"><a href="javascript:void(0)" class="back-link" data-key="'+parentKey.join('-')+'">'+this.writeSvg('mobile-menu-arrow')+'<span class="text">'+title+'</span></a></div>'
        });

        var inner = $('<div>', {
          class:'sites-content '+levelClass
        });


        for (var j = 0; j < items.length; j++) {
          var children = typeof items[j].children === 'object' && Array.isArray(items[j].children) ? items[j].children : [];
          var listItem = $('<div>', {
            html:$('<a>', {
              href: typeof items[j].link !== 'undefined' && children.length === 0 ? items[j].link : 'javascript:void(0)',
              class:"mobile-menu-item "+levelClass+"-item "+(typeof items[j].extraClass !== 'undefined' ? items[j].extraClass : '') + (children && children.length > 0 ? ' has-submenu' : ''),
              html: '<span class="text"><span>'+items[j].name+'</span>'+(typeof items[j].icon !== 'undefined' ? this.writeSvg(items[j].icon) : '')+'</span>'+(children.length > 0 ? '<span class="arrow-outer">'+this.writeSvg('mobile-menu-arrow')+'</span>' : ''),
              'data-key':key+'-'+j
            }),
          });

          inner.append(listItem);
        }

        content.append(inner);

        if (this.submenuContent !== undefined) {
          this.resetSubmenuContent(this.submenuContent);
        }

        this.submenuContent = content;
        this.currentLevel = level;
        this.innerContent.append(this.submenuContent);

        this.addMenuPageDOMEvents(this.submenuContent);
      }
    },
    setParams: function(params) {
      if (params.selectors) this.setSelectors(params.selectors);
      if (params.texts) this.setTexts(params.texts);
      if (params.sites) this.setSites(params.sites);
      if (params.ajaxUrl) this.setAjaxUrl(params.ajaxUrl);
      if (params.ajaxMethod) this.setAjaxMethod(params.ajaxMethod);
      if (params.loadWithAjax) this.setLoadWithAjax(params.loadWithAjax);
    },
    setAjaxUrl: function(ajaxUrl) {
      this.ajaxUrl = ajaxUrl;
    },
    setAjaxMethod: function(ajaxMethod) {
      this.ajaxMethod = ajaxMethod;
    },
    setLoadWithAjax: function(withAjax) {
      this.loadWithAjax = withAjax;
    },
    setSelectors: function(selectors) {
      this.selectors = $.extend({}, this.selectors, selectors);
    },
    setTexts: function(texts) {
      this.texts = $.extend({}, this.texts, texts);
    },
    setSites: function(sites) {
      if (typeof this.sites === 'object') {
        this.sites = sites;
      }
    },
    getSelector: function(key) {
      if (typeof this.selectors[key] === 'undefined') {
        throw key+' selector is undefined!';
      }

      return this.selectors[key];
    },
    getText: function(key) {
      return typeof this.texts[key] !== 'undefined' ? this.texts[key] : key;
    },
    writeSvg: function(icon) {
      return '<svg class="icon '+icon+'"><use xlink:href="#'+icon+'" /></svg>';
    },
    changeMenuVisibility: function() {
      if (!this.wait) {
        this.wait = true;
        var _self = this;
        var open = this.opened ? false : true;

        if (open) {
          $('body').addClass('opened-mobile-menu');
        } else {
          $('body').removeClass('opened-mobile-menu');
          if (this.submenuContent) {
            this.resetSubmenuContent(this.submenuContent);
          }
        }

        this.opened = open;
        this.currentLevel = 1;

        setTimeout(function(){
          _self.wait = false;
        }, 350);
      }
    },
    loadMenuItems: function() {
      if (!this.startLoad) {
        this.startLoad = true;
        var _self = this;

        $.ajax({
          url:this.ajaxUrl,
          type:this.ajaxMethod,
          dataType:'json',
          success: function(response) {
            if (typeof response.sites !== 'undefined') {
              _self.setParams(response);
              _self.createContent();
            }
          }
        });
      }
    },
    hasAjaxLoad: function() {
      return this.loadWithAjax;
    },
    hasInitialized: function() {
      return this.initialized;
    },
    hasAjaxLoadStarted: function() {
      return this.startLoad;
    }
  };

  window.MobileMenu = MobileMenu;
})(window);
