require('./inc/swiper.js');
require('./inc/site.js');

var homeSlider = undefined;

$(document).ready(function(){
  if ($('#homeSlider').find('li').length > 1) {
    initHomeSlider();
  }
});

function initHomeSlider() {
  var touchDevice = false;
	if (navigator.userAgent.match(/Android|webOS|iPhone|iPad|iPod|BlackBerry|IEMobile|Opera Mini/)) {
    touchDevice = true;
  }

  homeSlider = new Swiper('#homeSlider', {
    autoplay: {
      delay:20000
    },
    pagination: {
      el:'.swiper-pagination',
      clickable: true
    }
  });
}
